import {lazy} from 'react';

const LazyMapPage = lazy(() =>
  import(
    /* webpackChunkName: "MapPage" */
    './index'
  )
);

export default LazyMapPage;
