import {ApolloClient, ApolloLink, InMemoryCache} from '@apollo/client';
import {SentryLink} from 'apollo-link-sentry';
import {createUploadLink} from 'apollo-upload-client';
import polyfilledFetch from 'unfetch';
import {captureException} from '@sentry/browser';

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    new SentryLink(),
    createUploadLink({
      credentials: 'include',
      fetch: (uri: RequestInfo, options?: RequestInit) => {
        const params = new URLSearchParams();
        let operationName = 'unknown';

        try {
          if (options?.body) {
            if (typeof options.body === 'string') {
              operationName = JSON.parse(options.body).operationName;
            } else if (options.body instanceof FormData) {
              const properties: Record<string, any> = {};
              for (const [key, value] of (options.body as any).entries()) {
                properties[key] = value;
              }

              if (properties.operations) {
                operationName = JSON.parse(
                  properties.operations as string
                ).operationName;
              }
            }
          }
        } catch (error) {
          captureException(error);
        } finally {
          params.append('opname', operationName);
        }

        return polyfilledFetch(`/graphql?${params}`, options as any);
      },
    }),
  ]),
  cache: new InMemoryCache(),
});
