import {VERIFIED_USER_ROLES} from 'common/types';
import {useCurrentUser} from 'hooks';
import {Fragment} from 'react/jsx-runtime';

export type PrivateContainerProps = {
  component?: React.ComponentType<{children?: React.ReactNode}>;
  roles?: string[];
  children?: any;
};

const PrivateContainer = ({
  component: Component = Fragment,
  roles = VERIFIED_USER_ROLES,
  children,
}: PrivateContainerProps) => {
  const {user, isSignedIn} = useCurrentUser();

  if (!isSignedIn) {
    return null;
  }

  const isAccessDenied = user.role && !roles.includes(user.role);
  if (isAccessDenied) {
    return null;
  }

  return <Component>{children}</Component>;
};

export default PrivateContainer;
