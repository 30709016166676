import makeStyles from '@material-ui/styles/makeStyles';
import {Theme} from '@material-ui/core';

export default makeStyles(
  (theme: Theme) => ({
    failedContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    failedTitle: {
      marginBottom: theme.spacing(1),
    },
    loaderContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    },
    image: {
      '&[data-viewable]': {
        cursor: 'zoom-in',
      },
    },
  }),
  {
    name: 'ImageLoader',
  }
);
