import {lazy} from 'react';

const LazyAlgorithmsListPage = lazy(
  () =>
    import(
      /* webpackChunkName: "AlgorithmsListPage" */
      './index'
    )
);

export default LazyAlgorithmsListPage;
