import {ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles';
import {Theme} from '@material-ui/core';
import {createTheme} from '@material-ui/core';

import {
  LocalStorageKey,
  DEFAULT_THEME,
  Theme as InterfaceTheme,
} from 'common/types';
import {createContext, useEffect, useState} from 'react';

const lightTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      light: '#7154e5',
      main: '#7154e5',
      dark: '#7154e5',
    },
  },
});

const darkTheme = createTheme({
  palette: {
    type: 'dark',
    text: {
      primary: '#ffffff',
      secondary: '#81838e',
    },
    primary: {
      light: '#7154e5',
      main: '#7154e5',
      dark: '#7154e5',
    },
    background: {
      default: '#111421',
      paper: '#1d2135',
    },
  },
});

const MAP_THEME_TO_MUI_THEME = {
  [InterfaceTheme.Light]: lightTheme,
  [InterfaceTheme.Dark]: darkTheme,
};

interface IThemeContext {
  theme: InterfaceTheme;
  setTheme: (theme: InterfaceTheme) => void;
}

const ThemeContext = createContext<IThemeContext | null>(null);

const getLocalStorageTheme = () => {
  const theme = localStorage.getItem(LocalStorageKey.Theme);
  return (theme as InterfaceTheme) || DEFAULT_THEME;
};

const setLocalStorageTheme = (value) => {
  if (!Object.values(InterfaceTheme).includes(value)) {
    throw new Error('theme is not supported');
  }
  if (typeof value !== 'string') {
    throw new Error(
      `theme must be a string. Supported values: ${Object.values(
        InterfaceTheme
      ).join(', ')}`
    );
  }

  localStorage.setItem(LocalStorageKey.Theme, value);
};

type ThemeProviderProps = {
  children: React.ReactNode;
};

const ThemeProvider = ({children}: ThemeProviderProps) => {
  const [theme, setTheme] = useState<InterfaceTheme>(DEFAULT_THEME);

  const muiTheme: Theme = MAP_THEME_TO_MUI_THEME[theme];

  useEffect(() => {
    setTheme(getLocalStorageTheme());
  }, []);

  useEffect(() => {
    setLocalStorageTheme(theme);
  }, [theme]);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
      }}
    >
      <MuiThemeProvider
        theme={{
          ...muiTheme,
          palette: {
            ...muiTheme.palette,
            primary: {
              ...muiTheme.palette.primary,
            },
          },
          shape: {
            ...muiTheme.shape,
            borderRadius: 10,
          },
          overrides: {
            MuiTooltip: {
              tooltip: {
                fontSize: muiTheme.typography.fontSize,
              },
            },
            MuiInputBase: {
              root: {
                color: muiTheme.palette.text.primary,
                background:
                  muiTheme.palette.type === 'dark' ? '#352a67' : '#866bff57',
              },
            },
            MuiContainer: {
              root: {
                paddingLeft: 0,
                paddingRight: 0,
              },
            },
            MuiChip: {
              root: {
                fontWeight: muiTheme.typography.fontWeightMedium,
                '&:focus': {
                  borderColor: muiTheme.palette.primary.main,
                },
              },
            },
          },
        }}
      >
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export {ThemeContext, ThemeProvider};
