import {lazy} from 'react';

const LazySignInPage = lazy(
  () =>
    import(
      /* webpackChunkName: "SignInPage" */
      './index'
    )
);

export default LazySignInPage;
