import Loader from './Loader';
import cx from 'classnames';

import useStyles from './styles';
import {useEffect, useState} from 'react';

const PROGRESS_APPEAR_TIMEOUT_MS = 500;

const LoadingPage = () => {
  const [doShowProgress, setDoShowProgress] = useState(false);

  useEffect(() => {
    const progressTimeout = setTimeout(
      () => setDoShowProgress(true),
      PROGRESS_APPEAR_TIMEOUT_MS
    );

    return () => {
      clearTimeout(progressTimeout);
    };
  }, []);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Loader
        className={cx(classes.progress, {
          [classes.progressShow]: doShowProgress,
        })}
      />
    </div>
  );
};

export default LoadingPage;
