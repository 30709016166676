import {Route, Routes} from 'react-router-dom';

import {UserRole} from 'common/types';
import PrivateContainer from 'components/PrivateContainer';
import PrivateRoute from 'components/PrivateRoute';
import Header from './Header';
import useStyles from './styles';
import LoadingPage from 'pages/LoadingPage';
import LazyUsersListPage from 'pages/UsersListPage/lazy';
import LazyUserPage from 'pages/UserPage/lazy';
import LazyTasksAddPage from 'pages/TasksAddPage/lazy';
import LazyTasksModeratePage from 'pages/TasksModeratePage/lazy';
import LazySignInPage from 'pages/SignInPage/lazy';
import LazyMapPage from 'pages/MapPage/lazy';
import LazyIndexPage from 'pages/IndexPage/lazy';
import LazyAlgorithmsListPage from 'pages/AlgorithmsListPage/lazy';
import LazyAlgorithmPage from 'pages/AlgorithmPage/lazy';
import LazyAlgorithmSettingsPage from 'pages/AlgorithmSettingsPage/lazy';
import LazyAlgorithmCreatePage from 'pages/AlgorithmCreatePage/lazy';
import LazyAdminPage from 'pages/AdminPage/lazy';
import LazyAccessDeniedPage from 'pages/AccessDeniedPage/lazy';
import {PageRoute} from 'common/types';
import {useCurrentUser} from 'hooks';
import {Suspense} from 'react';

const RenderIfAppReady = ({children}) => {
  const {isAppReady} = useCurrentUser();

  if (!isAppReady) {
    return <LoadingPage />;
  }

  return children;
};

const RootPage = () => {
  const classes = useStyles();

  return (
    <>
      <PrivateContainer
        roles={[
          UserRole.NewUser,
          UserRole.Moderator,
          UserRole.MlEngineer,
          UserRole.Admin,
          UserRole.Root,
          UserRole.None,
        ]}
      >
        <Header />
      </PrivateContainer>
      <div className={classes.root}>
        <Suspense fallback={<LoadingPage />}>
          <RenderIfAppReady>
            <Routes>
              <Route
                path={PageRoute.AccessDenied}
                element={
                  <PrivateRoute
                    roles={[
                      UserRole.NewUser,
                      UserRole.Moderator,
                      UserRole.MlEngineer,
                      UserRole.Admin,
                      UserRole.Root,
                      UserRole.None,
                    ]}
                  >
                    <LazyAccessDeniedPage />
                  </PrivateRoute>
                }
              />
              <Route path={PageRoute.SignIn} element={<LazySignInPage />} />
              <Route
                path={PageRoute.Index}
                element={
                  <PrivateRoute>
                    <LazyIndexPage />
                  </PrivateRoute>
                }
              />
              <Route
                path={PageRoute.TasksAdd}
                element={
                  <PrivateRoute
                    roles={[UserRole.Root, UserRole.Admin, UserRole.MlEngineer]}
                  >
                    <LazyTasksAddPage />
                  </PrivateRoute>
                }
              />
              <Route
                path={PageRoute.TasksSimpleAdd}
                element={
                  <PrivateRoute
                    roles={[UserRole.Root, UserRole.Admin, UserRole.MlEngineer]}
                  >
                    <LazyTasksAddPage />
                  </PrivateRoute>
                }
              />
              <Route
                path={PageRoute.TasksModerate}
                element={
                  <PrivateRoute>
                    <LazyTasksModeratePage />
                  </PrivateRoute>
                }
              />

              <Route
                path={PageRoute.AlgorithmSettings}
                element={
                  <PrivateRoute
                    roles={[UserRole.Admin, UserRole.Root, UserRole.MlEngineer]}
                  >
                    <LazyAlgorithmSettingsPage />
                  </PrivateRoute>
                }
              />
              <Route
                path={PageRoute.AlgorithmAdd}
                element={
                  <PrivateRoute roles={[UserRole.Root]}>
                    <LazyAlgorithmCreatePage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/algorithms/:algorithmId/*"
                element={
                  <PrivateRoute>
                    <LazyAlgorithmPage />
                  </PrivateRoute>
                }
              />
              <Route
                path={PageRoute.AlgorithmsList}
                element={
                  <PrivateRoute>
                    <LazyAlgorithmsListPage />
                  </PrivateRoute>
                }
              />
              <Route
                path={PageRoute.User}
                element={
                  <PrivateRoute>
                    <LazyUserPage />
                  </PrivateRoute>
                }
              />
              <Route
                path={PageRoute.Map}
                element={
                  <PrivateRoute roles={[UserRole.Root]}>
                    <LazyMapPage />
                  </PrivateRoute>
                }
              />
              <Route
                path={PageRoute.UsersList}
                element={
                  <PrivateRoute roles={[UserRole.Admin, UserRole.Root]}>
                    <LazyUsersListPage />
                  </PrivateRoute>
                }
              />
              <Route
                path={PageRoute.Admin}
                element={
                  <PrivateRoute roles={[UserRole.Admin, UserRole.Root]}>
                    <LazyAdminPage />
                  </PrivateRoute>
                }
              />
            </Routes>
          </RenderIfAppReady>
        </Suspense>
      </div>
    </>
  );
};

export {RootPage};
