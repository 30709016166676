import {lazy} from 'react';

const LazyUserPage = lazy(
  () =>
    import(
      /* webpackChunkName: "UserPage" */
      './index'
    )
);

export default LazyUserPage;
