import {Navigate, useLocation} from 'react-router-dom';
import {PageRoute, UserRole} from 'common/types';
import {VERIFIED_USER_ROLES} from 'common/types';
import {createQueryString} from 'utils';
import {useCurrentUser} from 'hooks';

type PrivateRouteProps = {
  roles?: UserRole[];
  children: React.ReactNode;
};

const PrivateRoute = ({
  roles = VERIFIED_USER_ROLES,
  children,
}: PrivateRouteProps) => {
  const {isSignedIn, user} = useCurrentUser();
  const location = useLocation();

  if (!isSignedIn) {
    return (
      <Navigate
        to={{
          pathname: PageRoute.SignIn,
          search: createQueryString({
            from: location.pathname + location.search,
          }),
        }}
        replace
      />
    );
  }

  if (!roles.includes(user.role)) {
    return <Navigate to={PageRoute.AccessDenied} replace />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
