import {useContext} from 'react';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import { Theme } from 'common/types';
import { ThemeContext } from 'providers/ThemeProvider';
import useStyles from './styles';

function AppearanceDialog({ onClose, open }) {
  const { theme, setTheme } = useContext(ThemeContext);

  const isLightTheme = theme === Theme.Light;
  const isDarkTheme = theme === Theme.Dark;

  const classes = useStyles();

  return (
    <Dialog
      onClose={onClose}
      open={open}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <Paper elevation={0} className={classes.paper}>
        <Typography variant="h4" className={classes.title}>
          <FormattedMessage id="AppearanceDialog.title" />
        </Typography>
        <Button
          variant="outlined"
          onClick={() => setTheme(Theme.Light)}
          className={cx(classes.themeSelectItem, {
            [classes.themeSelectItemActive]: isLightTheme,
          })}
          color={isLightTheme ? 'primary' : undefined}
        >
          <Checkbox
            checked={isLightTheme}
            color="primary"
            className={classes.checkbox}
          />
          <FormattedMessage id="AppearanceDialog.theme.light" />
        </Button>
        <Button
          variant="outlined"
          onClick={() => setTheme(Theme.Dark)}
          className={cx(classes.themeSelectItem, {
            [classes.themeSelectItemActive]: isDarkTheme,
          })}
          color={isDarkTheme ? 'primary' : undefined}
        >
          <Checkbox checked={isDarkTheme} color="primary" />
          <FormattedMessage id="AppearanceDialog.theme.dark" />
        </Button>
      </Paper>
    </Dialog>
  );
}

export default AppearanceDialog;
