import {lazy} from 'react';

const LazyAlgorithmPage = lazy(
  () =>
    import(
      /* webpackChunkName: "AlgorithmPage" */
      './index'
    )
);

export default LazyAlgorithmPage;
