import ReactGA from 'react-ga';
import {Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import Menu, {MenuProps} from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Hidden from '@material-ui/core/Hidden';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import LanguageIcon from '@material-ui/icons/Language';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import GearIcon from '@material-ui/icons/Settings';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import {PopoverOrigin} from '@material-ui/core';

import {IntlContext} from 'providers/IntlProvider';
import {Locale, createUserPagePath} from 'common/types';
import AppearanceDialog from './AppearanceDialog';
import useStyles from './styles';
import UserInfoCard from 'components/UserInfoCard';
import PrivateContainer from 'components/PrivateContainer';
import {PageRoute, UserRole} from 'common/types';
import {useCurrentUser} from 'hooks';
import {firebaseAuth} from 'services/firebase';
import {useContext, useState} from 'react';

const MENU_ANCHOR_ORIGIN: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};

const MENU_TRANSFORM_ORIGIN: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

const UserMenu = (props: MenuProps) => {
  const {
    anchorOrigin = MENU_ANCHOR_ORIGIN,
    transformOrigin = MENU_TRANSFORM_ORIGIN,
    ...rest
  } = props;

  const {user} = useCurrentUser();
  const {locale, setLocale} = useContext(IntlContext)!;

  const [isAppearanceDialogOpen, setIsAppearanceDialogOpen] = useState(false);

  const handleAppearanceDialogOpen = () => {
    setIsAppearanceDialogOpen(true);
  };

  const handleAppearanceDialogClose = () => {
    setIsAppearanceDialogOpen(false);
  };

  const handleSignOutButtonClick = () => {
    firebaseAuth.signOut();
  };

  const handleLocaleChange = (_, newLocale) => {
    if (newLocale) {
      setLocale(newLocale);
    }
  };

  const classes = useStyles(props);

  return (
    <>
      <Menu
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        {...rest}
      >
        <UserInfoCard user={user} className={classes.userInfoCard} />
        <MenuItem
          component={Link}
          to={createUserPagePath({
            userId: user.userId,
          })}
        >
          <GearIcon className={classes.menuItemIcon} />
          <FormattedMessage id="UserMenu.admin" />
        </MenuItem>
        {/* @ts-ignore */}
        <Hidden smUp>
          <PrivateContainer roles={[UserRole.Admin, UserRole.Root]}>
            <MenuItem
              component={Link}
              to={PageRoute.Admin}
              onClick={() =>
                ReactGA.event({
                  category: 'UserMenu',
                  action: 'admin',
                })
              }
            >
              <PersonIcon className={classes.menuItemIcon} />
              <FormattedMessage id="UserMenu.account" />
            </MenuItem>
          </PrivateContainer>
        </Hidden>
        <MenuItem disableRipple className={classes.localeMenuItem}>
          <div className={classes.localeMenuItemLabel}>
            <LanguageIcon className={classes.menuItemIcon} />
            <FormattedMessage id="UserMenu.language" />
          </div>
          <ToggleButtonGroup
            exclusive
            value={locale}
            onChange={handleLocaleChange}
            size="small"
          >
            {Object.values(Locale).map((_locale) => (
              <ToggleButton value={_locale} key={_locale}>
                {_locale}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </MenuItem>
        <MenuItem onClick={handleAppearanceDialogOpen}>
          <ColorLensIcon className={classes.menuItemIcon} />
          <FormattedMessage id="UserMenu.appearance" />
        </MenuItem>
        <MenuItem onClick={handleSignOutButtonClick}>
          <ExitToAppIcon className={classes.menuItemIcon} />
          <FormattedMessage id="UserMenu.signOut" />
        </MenuItem>
      </Menu>
      <AppearanceDialog
        open={isAppearanceDialogOpen}
        onClose={handleAppearanceDialogClose}
      />
    </>
  );
};

export default UserMenu;
