import {lazy} from 'react';

const LazyTasksAddPage = lazy(
  () =>
    import(
      /* webpackChunkName: "TasksAddPage" */
      './index'
    )
);

export default LazyTasksAddPage;
