import {lazy} from 'react';

const LazyAlgorithmCreatePage = lazy(
  () =>
    import(
      /* webpackChunkName: "AlgorithmCreatePage" */
      './index'
    )
);

export default LazyAlgorithmCreatePage;
