import {lazy} from 'react';

const LazyAccessDeniedPage = lazy(
  () =>
    import(
      /* webpackChunkName: "AccessDeniedPage" */
      './index'
    )
);

export default LazyAccessDeniedPage;
