import {IntlProvider as BaseIntlProvider} from 'react-intl';
import {Locale, LocalStorageKey, DEFAULT_LOCALE} from 'common/types';
import 'moment/locale/uk';
import {createContext, useEffect, useState} from 'react';

interface IIntlContext {
  locale: string;
  messages: Record<string, string>;
  setLocale: (locale: string) => void;
}

const IntlContext = createContext<IIntlContext | null>(null);

const fetchMessages = async (locale) => {
  const messages =
    locale === Locale.UK
      ? (
          await import(
            /* webpackChunkName: "messages/uk" */
            'messages/uk'
          )
        ).default
      : (
          await import(
            /* webpackChunkName: "messages/en" */
            'messages/en'
          )
        ).default;

  return messages;
};

const fetchLocaleData = async (locale) => {
  if (locale === Locale.UK) {
    await import(
      /* webpackChunkName: "locale-data/uk" */
      '@formatjs/intl-relativetimeformat/dist/locale-data/uk'
    );
  } else {
    await import(
      /* webpackChunkName: "locale-data/en" */
      '@formatjs/intl-relativetimeformat/dist/locale-data/en'
    );
  }
};

const getLocalStorageLocale = () => {
  const locale = localStorage.getItem(LocalStorageKey.Locale);

  if (!locale || !(Object.values(Locale) as string[]).includes(locale)) {
    return DEFAULT_LOCALE;
  }

  return locale;
};

const setLocalStorageLocale = (value) => {
  if (!Object.values(Locale).includes(value)) {
    throw new Error('locale is not supported');
  }
  if (typeof value !== 'string') {
    throw new Error(
      `locale must be a string. Supported values: ${Object.values(Locale).join(
        ', '
      )}`
    );
  }

  localStorage.setItem(LocalStorageKey.Locale, value);
};

type IntlProviderProps = {
  children: React.ReactNode;
};

const IntlProvider = ({children}: IntlProviderProps) => {
  const [locale, setLocale] = useState<string | null>(null);
  const [messages, setMessages] = useState<Record<string, string> | null>(null);

  useEffect(() => {
    setLocale(getLocalStorageLocale());
  }, []);

  useEffect(() => {
    if (!locale) {
      return;
    }

    setLocalStorageLocale(locale);

    fetchMessages(locale).then(setMessages);

    fetchLocaleData(locale);
  }, [locale]);

  if (!locale || !messages) {
    return null;
  }

  return (
    <IntlContext.Provider
      value={{
        messages,
        locale,
        setLocale,
      }}
    >
      {/* @ts-ignore */}
      <BaseIntlProvider locale={locale} messages={messages}>
        {children}
      </BaseIntlProvider>
    </IntlContext.Provider>
  );
};

export {IntlContext, IntlProvider};
