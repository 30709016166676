import Avatar, {AvatarProps} from '@material-ui/core/Avatar';

type UserAvatarProps = AvatarProps & {
  name: string;
  photoURL?: string | null | undefined;
};

const UserAvatar = ({name, photoURL, ...rest}: UserAvatarProps) => {
  if (photoURL) {
    return <Avatar src={photoURL} alt={`${name} avatar`} {...rest} />;
  }
  return <Avatar {...rest}>{name ? name[0].toUpperCase() : null}</Avatar>;
};

export default UserAvatar;
