import {lazy} from 'react';

const LazyTasksModeratePage = lazy(
  () =>
    import(
      /* webpackChunkName: "TasksModeratePage" */
      './index'
    )
);

export default LazyTasksModeratePage;
