import React, {ReactNode, useState, useEffect, useCallback} from 'react';
import {Dialog} from '@material-ui/core';
import ImageLoader from 'components/ImageLoader';
import {useVisible} from 'hooks';
import {getBestImageSrc} from './utils';
import {useStyles} from './styles';

type ImageViewerProviderProps = {
  children: ReactNode;
};

export const ImageViewerProvider = ({children}: ImageViewerProviderProps) => {
  const classes = useStyles();
  const [currentImageUrl, setCurrentImageUrl] = useState<string | null>(null);
  const dialog = useVisible();

  const showImage = useCallback(
    (imageUrl: string) => {
      setCurrentImageUrl(imageUrl);

      dialog.show();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dialog.show]
  );

  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      const el = event.target as HTMLElement;

      const isViewableImage =
        el.tagName === 'IMG' && typeof el.dataset.viewable !== 'undefined';

      if (isViewableImage) {
        const imageSource = getBestImageSrc(el as HTMLImageElement);

        if (imageSource) {
          showImage(imageSource);
        }
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [showImage]);

  return (
    <>
      {children}
      <Dialog
        onClose={dialog.hide}
        open={dialog.isVisible}
        maxWidth="lg"
        // fullWidth
        classes={{paper: classes.container}}
      >
        {currentImageUrl && (
          <ImageLoader
            className={classes.image}
            src={currentImageUrl}
            onClick={dialog.hide}
            viewable={false}
          />
        )}
      </Dialog>
    </>
  );
};
