import {lazy} from 'react';

const LazyUsersListPage = lazy(
  () =>
    import(
      /* webpackChunkName: "UsersListPage" */
      './index'
    )
);

export default LazyUsersListPage;
