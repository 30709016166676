import {createRoot} from 'react-dom/client';

import {App} from './App';

import './setupPolyfills';
import './setupSentry';
import './setupAnalytics';
import './services/firebase';

const container = document.getElementById('root');

const root = createRoot(container!);

root.render(<App />);
