import {initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';

const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyD2NAgib9Az_9gm2fEqbJEoM-M7xzyl_Vo',
  authDomain: 'flair-cortex.firebaseapp.com',
  databaseURL: 'https://flair-cortex.firebaseio.com',
  projectId: 'flair-cortex',
  storageBucket: '',
  messagingSenderId: '629020241970',
  appId: '1:629020241970:web:42b20912e14a7759',
};

const firebaseApp = initializeApp(FIREBASE_CONFIG);
const firebaseAuth = getAuth(firebaseApp);

export {firebaseApp, firebaseAuth};
