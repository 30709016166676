export const getBestImageSrc = (img: HTMLImageElement) => {
  if (!img) return null;

  let srcset = img.getAttribute('srcset');
  let sizes = img.getAttribute('sizes'); // Used for width-based selection
  let devicePixelRatio = window.devicePixelRatio || 1; // Get current screen density

  if (srcset) {
    let sources = srcset.split(',').map((src) => {
      let [url, descriptor] = src.trim().split(/\s+/);

      if (!descriptor) return {url, value: Infinity}; // Default if no descriptor

      if (descriptor.endsWith('w')) {
        return {url, value: parseInt(descriptor.replace('w', ''), 10)};
      } else if (descriptor.endsWith('x')) {
        return {url, value: parseFloat(descriptor.replace('x', '')) * 1000}; // Normalize x values
      }

      return {url, value: 0};
    });

    if (sizes) {
      // If sizes exist, try to estimate correct width
      let bestWidth = window.innerWidth * devicePixelRatio; // Estimate needed width

      sources.sort((a, b) => a.value - b.value); // Sort ascending

      let bestMatch =
        sources.find((src) => src.value >= bestWidth) ||
        sources[sources.length - 1];

      return bestMatch.url || img.src;
    } else {
      // If no sizes, use pixel density (`1x`, `2x`, `3x`)
      sources.sort((a, b) => b.value - a.value); // Sort descending

      return sources[0]?.url || img.src;
    }
  }

  return img.src;
};
