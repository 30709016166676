import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {FormattedMessage} from 'react-intl';
import cx from 'classnames';

import useStyles from './styles';
import {useCallback, useEffect, useState} from 'react';

const loadImage = (
  src?: string,
  srcSet?: string
): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    if (src) {
      image.src = src;
    }
    if (srcSet) {
      image.srcset = srcSet;
    }

    if (image.complete) {
      return resolve(image);
    }

    image.onload = () => resolve(image);
    image.onerror = reject;
    return image;
  });
};

type ImageLoaderProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  loader?: React.ElementType;
  setIsTaskLoaded?: Function;
  viewable?: boolean;
};

const ImageLoader = ({
  loader,
  setIsTaskLoaded = () => null,
  viewable = true,
  ...rest
}: ImageLoaderProps) => {
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);
  const [isFailed, setIsFailed] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState<boolean>(false);

  const handleImageLoad = useCallback(() => {
    if (isMounted) {
      setIsImageLoaded(true);
    }
  }, [isMounted]);

  const handleImageLoadFail = useCallback(() => {
    if (isMounted) {
      setIsFailed(true);
    }
  }, [isMounted]);

  const handleRetryButtonClick = () => {
    setIsImageLoaded(false);
    setIsFailed(false);
    loadImage(rest.src, rest.srcSet)
      .then(handleImageLoad)
      .catch(handleImageLoadFail);
  };

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    if (isImageLoaded) {
      setIsTaskLoaded(true);
    } else if (isFailed) {
      setIsTaskLoaded(false);
    }
  }, [isImageLoaded, isFailed, setIsTaskLoaded]);

  useEffect(() => {
    setIsImageLoaded(false);
    setIsFailed(false);
    loadImage(rest.src, rest.srcSet)
      .then(handleImageLoad)
      .catch(handleImageLoadFail);
  }, [rest.src, rest.srcSet, handleImageLoad, handleImageLoadFail]);

  const classes = useStyles();

  if (isFailed) {
    return (
      <div className={classes.failedContainer}>
        <Typography variant="h4" className={classes.failedTitle}>
          <FormattedMessage id="ImageLoader.failed" />
        </Typography>
        <Button variant="outlined" onClick={handleRetryButtonClick}>
          <FormattedMessage id="ImageLoader.retry" />
        </Button>
      </div>
    );
  }

  return isImageLoaded ? (
    // eslint-disable-next-line
    <img
      {...rest}
      className={cx(classes.image, rest.className)}
      data-viewable={viewable ? '' : undefined}
    />
  ) : typeof loader === 'undefined' ? (
    <div className={classes.loaderContainer}>
      <CircularProgress />
    </div>
  ) : null;
};

export default ImageLoader;
