import {lazy} from 'react';

const LazyIndexPage = lazy(
  () =>
    import(
      /* webpackChunkName: "IndexPage" */
      './index'
    )
);

export default LazyIndexPage;
