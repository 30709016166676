import {lazy} from 'react';

const LazyAlgorithmSettingsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "AlgorithmSettingsPage" */
      './index'
    )
);

export default LazyAlgorithmSettingsPage;
