import {lazy} from 'react';

const LazyAdminPage = lazy(
  () =>
    import(
      /* webpackChunkName: "AdminPage" */
      './index'
    )
);

export default LazyAdminPage;
