import {useState, useEffect, useCallback} from 'react';

export function useLocalStorage<T>(key: string, initialValue: T) {
  const getStoredValue = useCallback((): T => {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(`Error reading localStorage key "${key}":`, error);
      return initialValue;
    }
  }, [key, initialValue]);

  const [storedValue, setStoredValue] = useState<T>(getStoredValue);

  const setValue = useCallback(
    (value: T | ((prev: T) => T)) => {
      setStoredValue((prev) => {
        const newValue = value instanceof Function ? value(prev) : value;

        if (newValue !== prev) {
          localStorage.setItem(key, JSON.stringify(newValue));
          window.dispatchEvent(
            new CustomEvent('local-storage-update', {detail: {key, newValue}})
          );
        }

        return newValue;
      });
    },
    [key]
  );

  // Effect to listen for changes from other instances
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === key) {
        const newValue = event.newValue
          ? JSON.parse(event.newValue)
          : initialValue;
        setStoredValue((prev) => (prev !== newValue ? newValue : prev));
      }
    };

    const handleCustomEvent = (event: Event) => {
      const {key: eventKey, newValue} = (event as CustomEvent).detail;
      if (eventKey === key) {
        setStoredValue((prev) => (prev !== newValue ? newValue : prev));
      }
    };

    window.addEventListener('storage', handleStorageChange);
    window.addEventListener('local-storage-update', handleCustomEvent);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('local-storage-update', handleCustomEvent);
    };
  }, [key, initialValue]);

  return [storedValue, setValue] as const;
}
