import * as Sentry from '@sentry/browser';
import {useMutation} from '@apollo/client';

import {SignInMutation, SignOutMutation} from 'common/graphql';
import {IUser, UserRole} from 'common/types';
import {apolloClient} from 'apollo';
import {firebaseAuth} from 'services/firebase';
import {createContext, useEffect, useState} from 'react';

export interface IUserContext {
  user: {
    userId: IUser['userId'];
    identifier: IUser['identifier'];
    role: IUser['role'];
    fullName: IUser['fullName'];
    photoURL: IUser['photoURL'];
  };
  isSignedIn: boolean;
  isAppReady: boolean;
}

const DEFAULT_USER = {
  userId: 0,
  identifier: '',
  role: UserRole.None,
  fullName: '',
  photoURL: null,
};

const UserContext = createContext<IUserContext | null>(null);

type UserProviderProps = {
  children: React.ReactNode;
};

const UserProvider = ({children}: UserProviderProps) => {
  const [isAppReady, setIsAppReady] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);

  const [user, setUser] = useState<{
    userId: IUser['userId'];
    identifier: IUser['identifier'];
    role: IUser['role'];
    fullName: IUser['fullName'];
    photoURL: IUser['photoURL'];
  }>(DEFAULT_USER);

  const [signIn, {error: signInError, data: signInData}] =
    useMutation(SignInMutation);

  const [signOut, {error: signOutError, data: signOutData}] =
    useMutation(SignOutMutation);

  useEffect(() => {
    firebaseAuth.onAuthStateChanged(async (_user) => {
      if (_user) {
        const idToken = await _user.getIdToken();
        const {photoURL, displayName, uid, email, providerData} = _user;

        signIn({
          variables: {
            idToken,
            uid,
            // @ts-ignore
            identifier: email || providerData[0].email,
            fullName: displayName || '',
            photoURL,
          },
        });
      } else {
        signOut();
      }
    });
  }, [signIn, signOut]);

  useEffect(() => {
    if (signInData) {
      setUser({
        userId: signInData.signIn.userId,
        identifier: signInData.signIn.identifier,
        role: signInData.signIn.role,
        fullName: signInData.signIn.fullName,
        photoURL: signInData.signIn.photoURL,
      });

      Sentry.setUser({
        email: signInData.signIn.identifier,
        id: signInData.signIn.userId,
      });

      setIsAppReady(true);
      setIsSignedIn(true);
    }
  }, [signInData]);

  useEffect(() => {
    if (signInError) {
      setUser(DEFAULT_USER);
      setIsAppReady(true);
      setIsSignedIn(false);
      Sentry.captureException(signInError);
    }
  }, [signInError]);

  useEffect(() => {
    if (signOutData || signOutError) {
      setUser(DEFAULT_USER);
      setIsAppReady(true);
      setIsSignedIn(false);
      apolloClient.clearStore();
    }
  }, [signOutData, signOutError]);

  return (
    <UserContext.Provider
      value={{
        user,
        isSignedIn,
        isAppReady,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export {UserContext, UserProvider};
