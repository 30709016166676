import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    maxHeight: '80vh',
    overflow: 'hidden',
  },
  image: {
    cursor: 'zoom-out',
    minHeight: 0,
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
}));
